import { Box, BoxProps, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  screenWidth: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
}));

export default function HeaderBox(props: React.PropsWithChildren<BoxProps>) {
  const classes = useStyles();
  return <Box className={classes.screenWidth} mb={1} {...props} />;
}
