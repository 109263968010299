import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "next-i18next";
import React from "react";
import { IEvent } from "../../api/types/event";
import { IUseDialogProps } from "../../hooks/useDialog";
import DialogTitleWithClose from "../DialogTitleWithClose";
import { VerticalSpacer } from "../utils/spacers";

interface IConferenceDetailsDialogProps extends IUseDialogProps {
  event: IEvent;
}

const namespaces = ["common", "conference_details_dialog"];

const useStyles = makeStyles((theme) => ({
  links: {
    "& a": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function ConferenceDetailsDialog({
  open,
  onCloseDialog,
  event,
}: IConferenceDetailsDialogProps) {
  const { t } = useTranslation(namespaces);
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitleWithClose
        onClose={onCloseDialog}
        title={t("conference_details_dialog:title")}
      />
      <DialogContent>
        <Typography>{t("conference_details_dialog:subtitle")}</Typography>
        <ul>
          <li>
            <Typography
              dangerouslySetInnerHTML={{
                __html: t("conference_details_dialog:phoneNumberMontreal"),
              }}
            />
          </li>
          <li>
            <Typography
              dangerouslySetInnerHTML={{
                __html: t("conference_details_dialog:phoneNumberToronto"),
              }}
            />
          </li>
          <li>
            <Typography
              dangerouslySetInnerHTML={{
                __html: t("conference_details_dialog:phoneNumberTollFree"),
              }}
            />
          </li>
          {event.meetingPhonePresenterPin && (
            <li>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: t("conference_details_dialog:phonePresenterPin", {
                    pin: event.meetingPhonePresenterPin,
                  }),
                }}
              />
            </li>
          )}

          {event.meetingPhoneListenerPin && (
            <li>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: t("conference_details_dialog:phoneParticipantPin", {
                    pin: event.meetingPhoneListenerPin,
                  }),
                }}
              />
            </li>
          )}
        </ul>

        <VerticalSpacer size={2} />

        <Typography
          className={classes.links}
          dangerouslySetInnerHTML={{
            __html: t("conference_details_dialog:helpText"),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onCloseDialog}>
          {t("common:close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConferenceDetailsDialog.namespaces = [...namespaces];
