import { Button, makeStyles } from "@material-ui/core";
import { useTranslation } from "next-i18next";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { IEvent } from "../../../api/types/event";
import { eventsActions } from "../../../store/modules/events";

interface IEventPreviewInvitationFormProps {
  event: IEvent;
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  formElement: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const namespaces = ["common", "errors", "event_review", "event_actions"];

export default function EventPreviewInvitationForm({
  event,
}: IEventPreviewInvitationFormProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(namespaces);

  const handleClick = useCallback(() => {
    dispatch(
      eventsActions.sendTestEmail({
        eventId: event.id,
        type: "invitation",
      })
    );
  }, [event.id]);

  return (
    <Button
      color="secondary"
      variant="contained"
      size="large"
      className={classes.formElement}
      onClick={handleClick}
    >
      {t("event_review:sendTestInvitation")}
    </Button>
  );
}

EventPreviewInvitationForm.namespaces = [...namespaces];
