import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import { EventState } from "@prisma/client";
import { useTranslation } from "next-i18next";
import React, { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { State } from "../../api/types";
import { IEvent, IEventParticipant } from "../../api/types/event";
import { IEventResults } from "../../api/types/poll";
import { useLanguage } from "../../hooks/useLanguage";
import { useMenu } from "../../hooks/useMenu";
import { selectAllEvents } from "../../store/modules/events/selectors";
import { prettyFormatEventDate } from "../../utils/date";
import Link from "../Link";
import AddToCalendar from "./AddToCalendar";
import EventBadge from "./EventBadge";
import EventListItemSubBox from "./EventListItemSubBox";

const namespaces = ["common", "event_detail"];

interface IEventListItemProps {
  event: IEvent;
  results: IEventResults;
  participants: IEventParticipant[];
  isPast?: boolean;
  onDeleteClick: (event: IEvent) => void;
  onConferenceDetailsClick: (event: IEvent) => void;
  onPublicShareClick: (event: IEvent) => void;
  onDuplicateClick: (event: IEvent) => void;
}

const useStyles = makeStyles((theme) => ({
  cardContent: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    paddingTop: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  cardLink: {
    textDecoration: "none",
    underline: "none",
  },
  eventName: {
    color: theme.palette.grey[500],
    fontWeight: 600,
  },
  eventProperty: {
    color: theme.palette.text.hint,
    fontWeight: 400,
  },
  eventDate: {
    color: theme.palette.text.hint,
  },
  eventStatBox: {
    flex: 2,
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  eventState: {
    color: theme.palette.text.hint,
    paddingTop: theme.spacing(1),
  },
  bold: {
    fontWeight: 600,
  },
  iconBtn: {
    height: 40,
    width: 40,
  },
}));

export default function EventListItem({
  event,
  participants,
  isPast,
  onDeleteClick,
  onConferenceDetailsClick,
  onPublicShareClick,
  onDuplicateClick,
}: IEventListItemProps) {
  const classes = useStyles();
  const { t } = useTranslation(namespaces);
  const { language } = useLanguage();
  const quorum = useSelector(selectAllEvents)?.quorums?.[event.id];

  const menu = useMenu();
  const isCompleted = event.state === State.completed;

  const handleClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();

      menu.toggle(e);
    },
    [menu.toggle]
  );

  const handleDeleteClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();

      onDeleteClick(event);
      menu.onCloseMenu(e);
    },
    [onDeleteClick, event]
  );

  const handleDuplicateClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();

      onDuplicateClick(event);
      menu.onCloseMenu(e);
    },
    [onDuplicateClick, event]
  );

  const handlePublicShareClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();

      onPublicShareClick(event);
      menu.onCloseMenu(e);
    },
    [onPublicShareClick, event]
  );

  const handleConferenceDetailsClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();

      onConferenceDetailsClick(event);
      menu.onCloseMenu(e);
    },
    [onDeleteClick, event]
  );

  function generateTimeInfo() {
    if (!event.start || !event.end) {
      return t("home:unscheduled");
    }

    return prettyFormatEventDate(
      new Date(event.start),
      new Date(event.end),
      language,
      event.timezone
    );
  }

  const proxyCount = useMemo(() => {
    if (!participants) {
      return 0;
    }

    return [
      ...new Set(
        participants.flatMap((participant) =>
          participant.represents
            .filter((rep) => rep.id !== participant.voterId)
            .flatMap((rep) => rep.id)
        )
      ),
    ].length;
  }, [participants]);

  return (
    <Grid item sm={12} key={event.id}>
      <Link
        naked
        className={classes.cardLink}
        href={`/event/${event.id}/${
          !event.hasFinishedSetup && event.adminKey ? "create" : ""
        }`}
      >
        <Card>
          <CardHeader
            avatar={
              <EventBadge
                textOnly
                state={event.state}
                invitationsSent={event.hasSentInvitation}
              />
            }
          />

          <CardContent className={classes.cardContent}>
            <Box flex={1}>
              <Typography
                variant="h6"
                className={classes.eventName}
                gutterBottom
              >
                {event.name}
              </Typography>
              {/*<Typography*/}
              {/*  variant="subtitle2"*/}
              {/*  className={classes.eventProperty}*/}
              {/*  gutterBottom*/}
              {/*>*/}
              {/*  {event.propertyName}*/}
              {/*</Typography>*/}
              <Typography variant="subtitle2" className={classes.eventDate}>
                {generateTimeInfo()}
              </Typography>
            </Box>
            <div className={classes.eventStatBox}>
              <EventListItemSubBox
                stat={event.state === State.awaiting ? "–" : `${quorum}%`}
                label={t("common:quorum")}
              />
              {/*{results?.participation?.votedCount !== 0 && (*/}
              {/*  <EventListItemSubBox*/}
              {/*    stat={*/}
              {/*      !results?.participation || event.state === State.awaiting*/}
              {/*        ? "–"*/}
              {/*        : `${results.participation.votedCount}/${results.participation.totalVoters}`*/}
              {/*    }*/}
              {/*    label={t("common:participation")}*/}
              {/*  />*/}
              {/*)}*/}
              <EventListItemSubBox
                stat={event.state === State.awaiting ? "–" : `${proxyCount}`}
                label={t("common:proxies")}
              />
            </div>
            <Box display="flex" justifyContent="center" alignItems="flex-start">
              {!isPast && event.state === EventState.awaiting && (
                <Box mr="4px">
                  <AddToCalendar event={event} className={classes.iconBtn} />
                </Box>
              )}
              <IconButton onClick={handleClick} className={classes.iconBtn}>
                <MoreVertRoundedIcon />
              </IconButton>
              <Menu
                anchorEl={menu.anchor}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={menu.open}
                onClose={menu.onCloseMenu}
              >
                {!isCompleted && (
                  <MenuItem onClick={handleConferenceDetailsClick}>
                    <Typography>
                      {t("event_actions:conferenceDetails")}
                    </Typography>
                  </MenuItem>
                )}
                <MenuItem onClick={handleDuplicateClick}>
                  <Typography> {t("event_actions:duplicate")}</Typography>
                </MenuItem>
                <MenuItem onClick={handleDeleteClick}>
                  <Typography>
                    {t(
                      `event_actions:${
                        event.state === EventState.awaiting
                          ? "cancel"
                          : "delete"
                      }`
                    )}
                  </Typography>
                </MenuItem>
                {!isCompleted && event.shareUrl && (
                  <MenuItem onClick={handlePublicShareClick}>
                    <Typography>
                      {t("event_actions:publicShareDetails")}
                    </Typography>
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
}

EventListItem.namespaces = [...namespaces, ...AddToCalendar.namespaces];
