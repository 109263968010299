import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { Box, DialogTitle, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import SquareIcon from "./SquareIcon";

interface IDialogTitleWithCloseProps {
  title?: string;

  onClose(): void;

  disabled?: boolean;
}

const useStyles = makeStyles(() => ({
  closeButton: {
    background: "none",
    border: 0,
    outline: 0,
    padding: 0,
    margin: 0,
    cursor: "pointer",
  },
}));

export default function DialogTitleWithClose({
  children,
  title,
  onClose,
  disabled,
}: React.PropsWithChildren<IDialogTitleWithCloseProps>) {
  const classes = useStyles();

  return (
    <DialogTitle>
      <Box display="flex" justifyContent="space-between">
        {title ? (
          <Typography variant="h3" data-qa="dialog-title">
            {title}
          </Typography>
        ) : (
          children
        )}

        <Box ml={3} mr={1}>
          <button
            onClick={onClose}
            disabled={disabled}
            className={classes.closeButton}
            type="button"
          >
            <SquareIcon color="#C2C5CD" icon={faTimes as IconProp} size="2x" />
          </button>
        </Box>
      </Box>
    </DialogTitle>
  );
}
