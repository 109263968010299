import { BoxProps, Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "next-i18next";
import React, { useEffect, useRef } from "react";
import { isElementInViewport } from "../utils/dom";

interface IErrorsProps {
  errors?: string[];
  boxProps?: BoxProps;
}

export function ErrorsBox({ errors, boxProps }: IErrorsProps) {
  const { t } = useTranslation("errors");
  const errorBoxRef = useRef(null);

  const filteredErrors = errors?.filter(Boolean);

  useEffect(() => {
    if (!errorBoxRef.current) {
      return;
    }

    if (!isElementInViewport(errorBoxRef.current)) {
      errorBoxRef.current.scrollIntoView();
    }
  }, [JSON.stringify(filteredErrors)]);

  if (!filteredErrors?.length) {
    return null;
  }

  return (
    <Box {...boxProps}>
      <span ref={errorBoxRef} data-qa="errors">
        {filteredErrors.map((error, index) => (
          <Alert key={`${index}-${error}`} severity="error">
            {t(error, { ns: "errors" })}
          </Alert>
        ))}
      </span>
    </Box>
  );
}
