// https://github.com/esetnik/react-add-to-calendar/blob/react-17/src/helpers/index.js
import { Prisma } from "@prisma/client";
import { createEvent, DateArray } from "ics";
import moment from "moment";
import { fromEmailLiteral } from "./constants";

export interface IAddToCalendarEvent {
  title?: string | undefined;
  description?: string | undefined;
  location?: string | undefined;
  startTime?: string | Date | undefined;
  endTime?: string | Date | undefined;
}

export enum CalendarType {
  google = "google",
  apple = "apple",
  outlookcom = "outlookcom",
  outlook = "outlook",
  yahoo = "yahoo",
  other = "other",
}

export function buildUrl(event: IAddToCalendarEvent, type: CalendarType) {
  let calendarUrl: string;

  // allow mobile browsers to open the gmail data URI within native calendar app
  // type = (type == "google" && this.isMobile()) ? "outlook" : type;

  switch (type) {
    case "google":
      calendarUrl = "https://calendar.google.com/calendar/render";
      calendarUrl += "?action=TEMPLATE";
      calendarUrl += "&dates=" + formatTime(event.startTime);
      calendarUrl += "/" + formatTime(event.endTime);
      calendarUrl += "&location=" + encodeURIComponent(event.location);
      calendarUrl += "&text=" + encodeURIComponent(event.title);
      calendarUrl += "&details=" + encodeURIComponent(event.description);
      break;

    case "yahoo": {
      // yahoo doesn't utilize endTime so we need to calulate duration
      const duration = calculateDuration(event.startTime, event.endTime);
      calendarUrl = "https://calendar.yahoo.com/?v=60&view=d&type=20";
      calendarUrl += "&title=" + encodeURIComponent(event.title);
      calendarUrl += "&st=" + formatTime(event.startTime);
      calendarUrl += "&dur=" + duration;
      calendarUrl += "&desc=" + encodeURIComponent(event.description);
      calendarUrl += "&in_loc=" + encodeURIComponent(event.location);
      break;
    }
    case "outlookcom":
      calendarUrl = "https://outlook.live.com/owa/?rru=addevent";
      calendarUrl += "&startdt=" + formatTime(event.startTime);
      calendarUrl += "&enddt=" + formatTime(event.endTime);
      calendarUrl += "&subject=" + encodeURIComponent(event.title);
      calendarUrl += "&location=" + encodeURIComponent(event.location);
      calendarUrl += "&body=" + encodeURIComponent(event.description);
      calendarUrl += "&allday=false";
      calendarUrl += "&uid=" + getRandomKey();
      calendarUrl += "&path=/calendar/view/Month";
      break;

    default:
      calendarUrl = [
        "BEGIN:VCALENDAR",
        "VERSION:2.0",
        "BEGIN:VEVENT",
        "URL:" + document.URL,
        "DTSTART:" + formatTime(event.startTime),
        "DTEND:" + formatTime(event.endTime),
        "SUMMARY:" + event.title,
        "DESCRIPTION:" + event.description,
        "LOCATION:" + event.location,
        "END:VEVENT",
        "END:VCALENDAR",
      ].join("\n");

      calendarUrl = encodeURI("data:text/calendar;charset=utf8," + calendarUrl);
  }

  return calendarUrl;
}

function calculateDuration(startTime, endTime) {
  // snag parameters and format properly in UTC
  const end = moment.utc(endTime).format("DD/MM/YYYY HH:mm:ss");
  const start = moment.utc(startTime).format("DD/MM/YYYY HH:mm:ss");

  // calculate the difference in milliseconds between the start and end times
  const difference = moment(end, "DD/MM/YYYY HH:mm:ss").diff(
    moment(start, "DD/MM/YYYY HH:mm:ss")
  );

  // convert difference from above to a proper momentJs duration object
  const duration = moment.duration(difference);

  return Math.floor(duration.asHours()) + moment.utc(difference).format(":mm");
}

function formatTime(date) {
  const formattedDate = moment.utc(date).format("YYYYMMDDTHHmmssZ");
  return formattedDate.replace("+00:00", "Z");
}

function getRandomKey() {
  const n = Math.floor(Math.random() * 999999999999).toString();
  return new Date().getTime().toString() + "_" + n;
}

function convertDateToDateArray(date: Date): DateArray {
  return [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
  ];
}

function generateCalendarInvite(
  event: Prisma.EventGetPayload<{
    include: {
      organization: true;
      documents: { include: { file: true } };
    };
  }>,
  link: string
) {
  const { error, value } = createEvent({
    title: event.name,
    description: link,
    organizer: {
      name: event.organization.name,
      email: fromEmailLiteral,
    },
    start: convertDateToDateArray(new Date(event.start)),
    end: convertDateToDateArray(new Date(event.end)),
    status: "CONFIRMED",
    method: "REQUEST",
  });

  if (error) {
    return;
  }

  return value;
}

export function generateCalendarInviteAttachment(
  event: Prisma.EventGetPayload<{
    include: {
      organization: true;
      documents: { include: { file: true } };
    };
  }>,
  link: string
) {
  return Buffer.from(generateCalendarInvite(event, link)).toString("base64");
}
