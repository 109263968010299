export const poweredByLanguage = {
  en: "/static/images/powered-by.svg",
  fr: "/static/images/powered-by-fr.svg",
};

export const maxBasicPlanUnits = 30;

export const maxUnits = 500;

export const UNIT_DEFAULT_VALUE = 1;

export const fromEmailLiteral = "admin@getpropty.com";

export const dialogCloseDelay = 500;
