import { makeStyles } from "@material-ui/core";
import { EventPollState } from "@prisma/client";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import React from "react";
import { State } from "../../api/types";

interface IEventBadgeProps {
  state: State | EventPollState;
  textOnly?: boolean;
  invitationsSent?: boolean;
}

const useBadgeStyles = makeStyles((theme) => ({
  badge: {
    fontWeight: 500,
    fontSize: 16,
    borderRadius: theme.shape.borderRadius,
    padding: "3px 5px",
    width: "fit-content",
  },
  awaiting: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.common.white,
  },
  invitationsSent: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.info.contrastText,
  },
  ongoing: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.success.contrastText,
  },
  completed: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.info.contrastText,
  },
  badgeText: {
    fontSize: 14,
    fontWeight: 500,
  },
  awaitingText: {
    color: theme.palette.grey[300],
  },
  invitationsSentText: {
    color: theme.palette.warning.main,
  },
  ongoingText: {
    color: theme.palette.error.main,
  },
  completedText: {
    color: theme.palette.success.main,
  },
}));

const namespaces = ["event_detail"];

export default function EventBadge({
  state,
  textOnly = false,
  invitationsSent = false,
}: IEventBadgeProps) {
  const { t } = useTranslation(namespaces);
  const classes = useBadgeStyles();

  const trueState =
    state === State.awaiting && invitationsSent ? "invitationsSent" : state;

  return (
    <span
      className={clsx(
        classes[textOnly ? "badgeText" : "badge"],
        classes[textOnly ? `${trueState}Text` : trueState]
      )}
    >
      {t(trueState, { ns: "event_detail" })}
    </span>
  );
}

EventBadge.namespaces = [...namespaces];
