import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCalendarPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Menu,
  MenuItem,
  Typography,
  IconButton,
  IconButtonProps,
} from "@material-ui/core";
import { useTranslation } from "next-i18next";
import React from "react";
import { useSelector } from "react-redux";
import { IEvent } from "../../api/types/event";
import { useMenu } from "../../hooks/useMenu";
import { selectEnvironment } from "../../store/modules/enviroment/selectors";

import {
  buildUrl,
  CalendarType,
  IAddToCalendarEvent,
} from "../../utils/calendar";

const namespaces = ["mandate"];
interface IAddToCalendarProps extends IconButtonProps {
  event: IEvent;
}

export default function AddToCalendar({
  event,
  ...props
}: IAddToCalendarProps) {
  const { t } = useTranslation(namespaces);
  const menu = useMenu();
  const environment = useSelector(selectEnvironment);

  const calendarEvent: IAddToCalendarEvent = {
    title: event.name,
    location: "",
    description: t("mandate:calendarDescription", {
      link: `${environment.baseUrl}/event/${event.id}/meeting`,
    }),
    startTime: event.start,
    endTime: event.end,
  };

  return (
    <>
      <IconButton
        {...props}
        onClick={(e) => {
          e.preventDefault();
          menu.onOpenMenu(e);
        }}
      >
        <FontAwesomeIcon icon={faCalendarPlus as IconProp} size="sm" />
      </IconButton>
      <Menu
        anchorEl={menu.anchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={menu.open}
        onClose={menu.onCloseMenu}
      >
        {Object.values(CalendarType).map((type) => (
          <MenuItem
            key={type}
            component="a"
            href={buildUrl(calendarEvent, type)}
            onClick={menu.onCloseMenu}
            target="_blank"
          >
            <Typography>{t(`mandate:calendar.${type}`)}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

AddToCalendar.namespaces = [...namespaces];
