import { useCallback, useState } from "react";

export interface IUseDialogProps {
  open: boolean;
  onOpenDialog(): void;
  onCloseDialog(): void;
}

export default function useDialog(): IUseDialogProps {
  const [open, setOpen] = useState(false);

  const onOpenDialog = useCallback(() => {
    setOpen(true);
  }, []);
  const onCloseDialog = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    open,
    onOpenDialog,
    onCloseDialog,
  };
}
