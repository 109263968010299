import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { EventState } from "@prisma/client";
import { useTranslation } from "next-i18next";
import React, { useCallback } from "react";
import { IEvent } from "../../api/types/event";
import { IUseDialogProps } from "../../hooks/useDialog";

import DialogTitleWithClose from "../DialogTitleWithClose";

interface IDeleteEventDialogProps extends IUseDialogProps {
  event: IEvent;
  onSubmit(): void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
}));

const namespaces = ["common", "event_actions", "delete_event_modal"];

export default function DeleteEventDialog({
  event,
  open,
  onCloseDialog,
  onSubmit,
}: IDeleteEventDialogProps) {
  const { t } = useTranslation(namespaces);
  const classes = useStyles();

  const handleSubmit = useCallback(() => {
    onSubmit();
    onCloseDialog();
  }, [onSubmit]);

  return (
    <Dialog
      className={classes.container}
      onClose={onCloseDialog}
      open={open}
      fullWidth
      maxWidth="md"
      data-qa="delete-event-dialog"
    >
      <DialogTitleWithClose
        onClose={onCloseDialog}
        title={t("delete_event_modal:title")}
      />
      <DialogContent>
        <Box mb={2}>
          <Typography>{t("delete_event_modal:warningTitle")}</Typography>
        </Box>
        {event?.state === EventState.awaiting && event?.hasSentInvitation && (
          <Box mb={2}>
            <Typography>
              {t("delete_event_modal:warningParticipants")}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions disableSpacing>
        <Button
          className={classes.cancelButton}
          variant="text"
          onClick={onCloseDialog}
          data-qa="cancel"
        >
          {t("common:cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          data-qa="delete-event"
        >
          {t("common:delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteEventDialog.namespaces = [...namespaces];
