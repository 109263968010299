import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

interface IEventListItemSubBox {
  stat: string;
  label: string;
}

const useStyles = makeStyles((theme) => ({
  eventStatSubBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  eventStatValue: {
    fontWeight: 500,
  },
  eventStatLabel: {
    color: theme.palette.text.hint,
  },
}));

export default function EventListItemSubBox({
  stat,
  label,
}: IEventListItemSubBox) {
  const classes = useStyles();

  return (
    <div className={classes.eventStatSubBox}>
      <Typography variant="h5" className={classes.eventStatValue}>
        {stat}
      </Typography>
      <Typography gutterBottom className={classes.eventStatLabel}>
        {label}
      </Typography>
    </div>
  );
}
