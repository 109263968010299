import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Link as MuiLink,
} from "@material-ui/core";
import { useTranslation } from "next-i18next";
import React, { useCallback } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import { IEvent } from "../../api/types/event";
import { IUseDialogProps } from "../../hooks/useDialog";
import { snackbarActions } from "../../store/modules/snackbar";
import DialogTitleWithClose from "../DialogTitleWithClose";
import { VerticalSpacer } from "../utils/spacers";

interface IPublicShareDialogProps extends IUseDialogProps {
  event: IEvent;
}

const namespaces = ["common", "public_share_dialog"];

export default function PublicShareDialog({
  open,
  onCloseDialog,
  event,
}: IPublicShareDialogProps) {
  const { t } = useTranslation(namespaces);
  const dispatch = useDispatch();

  const onCopied = useCallback(() => {
    dispatch(
      snackbarActions.queue({
        message: "copied",
        options: {
          variant: "success",
        },
      })
    );
  }, []);

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitleWithClose
        onClose={onCloseDialog}
        title={t("public_share_dialog:title")}
      />
      <DialogContent>
        <Typography>{t("public_share_dialog:subtitle")}</Typography>

        <VerticalSpacer size={4} />

        <Typography>
          <MuiLink href={event.shareUrl} target="_blank">
            {event.shareUrl}
          </MuiLink>
        </Typography>

        <VerticalSpacer size={2} />

        <CopyToClipboard text={event.shareUrl} onCopy={onCopied}>
          <Button color="primary" variant="contained">
            {t("common:copy")}
          </Button>
        </CopyToClipboard>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onCloseDialog}>
          {t("common:close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PublicShareDialog.namespaces = [...namespaces];
