import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
import { EventType } from "@prisma/client";
import clsx from "clsx";
import { Form, Formik } from "formik";
import { useTranslation } from "next-i18next";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { IEvent } from "../../api/types/event";
import { IUseDialogProps } from "../../hooks/useDialog";
import { useIsLoading } from "../../hooks/useIsLoading";
import { eventsActions } from "../../store/modules/events";
import { buildDuplicateEventKey } from "../../store/modules/events/keys";
import { IDuplicateEvent } from "../../store/modules/events/types";
import { spreadField } from "../../utils/formik";
import { EventDuplicateSchema } from "../../utils/validation/event";
import DialogTitleWithClose from "../DialogTitleWithClose";
import EventPreviewInvitationForm from "../event/forms/EventPreviewInvitationForm";

interface IDuplicateEVentDialogProps extends IUseDialogProps {
  event: IEvent;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  confirmButton: {
    minWidth: 120,
  },
  labelWrapper: {
    marginTop: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    display: "flex",
    alignItems: "flex-start",
  },
  checkboxLabelWrapper: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  boldText: {
    fontWeight: 600,
    display: "inline",
  },
  labelText: {
    display: "block",
    paddingBottom: theme.spacing(1),
  },
}));

const namespaces = ["common", "meeting", "forms"];

export default function DuplicateEventDialog({
  open,
  onCloseDialog,
  event,
}: IDuplicateEVentDialogProps) {
  const { t } = useTranslation(namespaces);
  const dispatch = useDispatch();
  const classes = useStyles();
  const eventId = event.id;

  const isDuplicatingEvent = useIsLoading(buildDuplicateEventKey(event.id));
  const isNotConference = event?.type !== EventType.conference;

  const onSubmit = useCallback(
    (values: IDuplicateEvent) => {
      dispatch(
        eventsActions.duplicate({
          ...values,
          eventId,
        })
      );
    },
    [event.id]
  );

  return (
    <Dialog
      className={classes.container}
      onClose={onCloseDialog}
      open={open}
      fullWidth
      maxWidth="md"
      data-qa="duplicate-event-dialog"
    >
      <DialogTitleWithClose
        onClose={onCloseDialog}
        title={t("meeting:duplicateEvent.title")}
      />
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          eventId: event.id,
          documents: isNotConference,
          agenda: isNotConference,
          participants: true,
          polls: isNotConference,
        }}
        validationSchema={EventDuplicateSchema}
        validateOnMount={false}
        validateOnChange={false}
      >
        {(formikProps) => (
          <Form
            style={{
              display: "contents",
            }}
          >
            <DialogContent>
              <Box mb={2}>
                <Typography>{t("meeting:duplicateEvent.content")}</Typography>
              </Box>
              <Box mb={2}>
                <FormControlLabel
                  className={clsx(
                    classes.checkboxLabelWrapper,
                    classes.labelWrapper
                  )}
                  control={
                    <Checkbox
                      checked={formikProps.values.participants}
                      {...spreadField(formikProps, "participants", t, {
                        noHelperText: true,
                        noError: true,
                      })}
                    />
                  }
                  label={
                    <>
                      <Typography
                        className={clsx(classes.boldText)}
                        variant="body1"
                        gutterBottom
                      >
                        {t("forms:participants")}
                      </Typography>
                      <br />
                    </>
                  }
                />
              </Box>
              {isNotConference && (
                <>
                  <Box mb={2}>
                    <FormControlLabel
                      className={clsx(
                        classes.checkboxLabelWrapper,
                        classes.labelWrapper
                      )}
                      control={
                        <Checkbox
                          checked={formikProps.values.documents}
                          {...spreadField(formikProps, "documents", t, {
                            noHelperText: true,
                            noError: true,
                          })}
                        />
                      }
                      label={
                        <>
                          <Typography
                            className={clsx(classes.boldText)}
                            variant="body1"
                            gutterBottom
                          >
                            {t("forms:documents")}
                          </Typography>
                          <br />
                        </>
                      }
                    />
                  </Box>
                  <Box mb={2}>
                    <FormControlLabel
                      className={clsx(
                        classes.checkboxLabelWrapper,
                        classes.labelWrapper
                      )}
                      control={
                        <Checkbox
                          checked={formikProps.values.agenda}
                          {...spreadField(formikProps, "agenda", t, {
                            noHelperText: true,
                            noError: true,
                          })}
                        />
                      }
                      label={
                        <>
                          <Typography
                            className={clsx(classes.boldText)}
                            variant="body1"
                            gutterBottom
                          >
                            {t("forms:agenda")}
                          </Typography>
                          <br />
                        </>
                      }
                    />
                  </Box>
                  <Box mb={2}>
                    <FormControlLabel
                      className={clsx(
                        classes.checkboxLabelWrapper,
                        classes.labelWrapper
                      )}
                      control={
                        <Checkbox
                          checked={formikProps.values.polls}
                          {...spreadField(formikProps, "polls", t, {
                            noHelperText: true,
                            noError: true,
                          })}
                        />
                      }
                      label={
                        <>
                          <Typography
                            className={clsx(classes.boldText)}
                            variant="body1"
                            gutterBottom
                          >
                            {t("forms:polls")}
                          </Typography>
                          <br />
                        </>
                      }
                    />
                  </Box>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.cancelButton}
                variant="text"
                onClick={onCloseDialog}
                data-qa="cancel"
              >
                {t("common:cancel")}
              </Button>
              <Button
                className={classes.confirmButton}
                disabled={isDuplicatingEvent}
                variant="contained"
                color="primary"
                type="submit"
                data-qa="duplicate-event"
              >
                {isDuplicatingEvent ? (
                  <CircularProgress color="secondary" size={24} />
                ) : (
                  t("meeting:duplicateEvent.confirm")
                )}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

DuplicateEventDialog.namespaces = [
  ...namespaces,
  ...EventPreviewInvitationForm.namespaces,
];
